<template>
    <div>
        <companies-component />
    </div>
</template>

<script>
import CompaniesComponent from '@/components/admin/companies/companyList'
export default {
    name: "AdminCompanies",
    components: { CompaniesComponent },
    data: () => ({

    })
};
</script>
